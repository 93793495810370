import React from "react";

const formData = [
	{
		title: "Change Your Password",
		inputData: [
			{
				title: "Enter Email",
				type: "email",
			},
			{
				title: "Old Password",
				type: "password",
			},
			{
				title: "Enter New Password",
				type: "password",
			},
			{
				title: "Confirm New Password",
				type: "password",
			},
		],
		buttonText: "Update Password",
	},
	{
		title: "Change Your Email",
		inputData: [
			{
				title: "Enter Current Email",
				type: "email",
			},
			{
				title: "New Email",
				type: "email",
			},
			{
				title: "Confirm New Email",
				type: "email",
			},
		],
		buttonText: "Update Email",
	},
];


const SettingForm = () => (
	<form action="">
		{formData.map((data, j) => {
			const { title, inputData, buttonText } = data;
			return (
				<div className="mt-12" key={j}>
					<a href="#!" className="text-[17px] leading-tight text-blue-600">
						{title}
					</a>
					<div className="grid grid-cols-12">
						{inputData.map((dataItem, k) => (
							<div className="col-span-12 lg:col-span-5 mt-2" key={k}>
								<div className="flex flex-col p-2 mb-6">
									<label className="mb-2">{dataItem.title}</label>
									<input
										type={dataItem.type}
										className="h-12 p-4 bg-blue-50 dark:bg-slate-700 rounded-xl border-none focus:outline-none"
										id="email"
									/>
								</div>
							</div>
						))}
					</div>
					<div className="grid grid-cols-12">
						<div className="col-span-12 lg:col-span-5 mt-2">
							<div>
								<button className="bg-blue-600 text-white hover:bg-opacity-90 font-bold py-3.5 px-5 rounded-xl w-full">
									{buttonText}
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		})}

		<div className="mt-12">
			<p className="text-[17px] leading-tight text-blue-600 font-bold mb-4">
				Send Me Emails When:
			</p>
			<div>
				<div className="bg-transparent">
					<input
						className="bg-transparent dark:bg-transparent rounded border dark:border-slate-700"
						type="checkbox"
						value=""
						id="condition1"
					/>
					<label className="form-check-label" htmlFor="condition1">
						Laplo offers and deals on really cool Discounts.
					</label>
				</div>
				<div className="bg-transparent">
					<input
						className="bg-transparent dark:bg-transparent rounded border dark:border-slate-700"
						type="checkbox"
						value=""
						id="condition2"
						checked
					/>
					<label className="form-check-label" htmlFor="condition2">
						I have an upcoming reservation.
					</label>
				</div>
				<div className="bg-transparent">
					<input
						className="bg-transparent dark:bg-transparent rounded border dark:border-slate-700"
						type="checkbox"
						value=""
						id="condition3"
					/>
					<label className="form-check-label" htmlFor="condition3">
						Travelo has fun company news, as well as periodic emails.
					</label>
				</div>
			</div>
			<div className="grid grid-cols-12">
				<div className="col-span-12 lg:col-span-5 mt-6">
					<div>
						<button className="bg-blue-600 text-white hover:bg-opacity-90 font-bold py-3.5 px-5 rounded-xl w-full">
							Update All Setting
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
);

function EProfileSettings() {
	return (
		<div className="col-span-12 md:col-span-9 lg:col-span-10">
			<div className="bg-white dark:bg-slate-800 shadow-2xl rounded-xl p-6 py-12">
				<h3 className="text-3xl md:text-[40px] font-bold text-blue-600 leading-tight">
					Account Setting
				</h3>
				<SettingForm />
			</div>
		</div>
	);
}

export default EProfileSettings;

