import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faFire, faCogs } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const sidebarData = [
	{
		icon: faUser,
		title: "Profile",
		href: "/profile/info"
	},
	{
		icon: faFire,
		title: "Order Stories",
		href: "/profile/orders"
	},
	{
		icon: faCogs,
		title: "Setting",
		href: "/profile/settings"
	},
];

const ProfileSideBar = () => {
	const [activeTab, setActiveTab] = useState(0);
	const navigate  = useNavigate()

	const ActiveTab = (index) => {
		setActiveTab(index);
		navigate(sidebarData[index].href)
	};
	return (
		<div className="bg-white dark:bg-slate-800 shadow-2xl rounded-xl flex md:flex-col items-center justify-center">
			{sidebarData.map((item, i) => (
				<div
					className={`text-center w-full py-6 duration-500 cursor-pointer ${
						activeTab === i
							? "bg-blue-600 text-white rounded-xl"
							: "hover:bg-blue-600 hover:text-white hover:rounded-xl"
					}`}
					key={i}
					onClick={() => ActiveTab(i)}
				>
					<div className="text-3xl">
						<FontAwesomeIcon icon={item.icon} />
					</div>
					<p className="text-xl font-bold mt-2">{item.title}</p>
				</div>
			))}
		</div>
	);
};

export default ProfileSideBar