import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const FreeReturnsSection = () => {
	return (
		<section className="ezy__epincentives6_EyxX5tH2 py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative overflow-hidden z-10">
			<div className="container px-4 mx-auto">
				<div className="bg-white dark:bg-slate-800 rounded-lg p-6 lg:p-12">
					<div className="grid grid-cols-12 gap-6">
						<div className="col-span-12 md:col-span-4">
							<div className="flex items-center justify-center h-full">
								<div className="w-52 h-52 bg-gray-100 dark:bg-[#0b1727] rounded-full flex justify-center items-center text-[90px] text-blue-600 mx-auto">
									<FontAwesomeIcon icon={faBoxOpen} />
								</div>
							</div>
						</div>
						<div className="col-span-12 md:col-span-8">
							<h6 className="text-4xl font-bold mb-3">
								<em>Free Return</em>
							</h6>
							<p className="opacity-80">
								Sellers that offer 'Free Return' service will have 'Free Return'
								icon on their item details page. With 'Free Return' service, you
								get 15 days (on top of the on-time delivery date) to decide if
								you want to keep or return the item for any reason, as long as
								they are unused, unwashed and in their original condition –
								complete with the original packaging, tags, etc. It's easy and
								fast:
							</p>
							<h5 className="text-2xl mt-6 mb-2">Easy</h5>
							<p className="opacity-80">
								• Return items locally and easily: 1) Get the return code or
								label, 2) wait for door-to-door pickup service or take your
								package to the designated service point and, 3) lastly, wait for
								the local warehouse to confirm receipt of your returned package.
							</p>
							<p className="opacity-80 mt-2">
								• Remember to select 'Return Goods" while opening the dispute.
							</p>

							<h5 className="text-2xl mt-6 mb-2">Fast</h5>
							<p className="opacity-80">
								• Return items locally and easily: 1) Get the return code or
								label, 2) wait for door-to-door pickup service or take your
								package to the designated service point and, 3) lastly, wait for
								the local warehouse to confirm receipt of your returned package.
							</p>
							<p className="opacity-80 mt-2">
								• Remember to select 'Return Goods" while opening the dispute.
							</p>

							<h5 className="text-2xl mt-4 mb-2">Service Scope</h5>
							<p className="opacity-80">
								• Not all countries have Free Return service. At present,
								supported countries include the mainland territories of the
								United States, Canada, Australia, United Kingdom, France, Spain,
								Germany, Russia, South Korea, Saudi Arabia, UAE, Italy,
								Netherlands, Poland, Brazil and Israel.
							</p>
							<p className="opacity-80 mt-2">
								• In addition, not all products on AliExpress.com include the
								Free Return service. The seller can choose whether or not to
								offer this extra service. Sellers that offer the Free Return
								service will have a label or icon on their product details page
								confirming this service is available.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FreeReturnsSection;

