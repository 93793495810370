import React, {Fragment} from 'react';
import Epreview from "../components/Epreview";
import Epreview2 from "../components/Epreview2";


const ProductReviews = () => {
    return (
        <Fragment>

            <Epreview />
			<Epreview2 />

        </Fragment>
    );
}

export default ProductReviews;
