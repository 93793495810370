import { BrowserRouter as Router } from "react-router-dom";
import "./style.css";
import AppRoutes from './AppRoutes.js';
import Navigation from "./components/Navigation";

const App = () => {
    return (
        <div className="App">
            
            <Router>
                <Navigation />
                <div className="app-body" style={{
                    margin: '0 20%'
                }}>
                    <AppRoutes />
                </div>
                
            </Router>
        </div>
    );
};

export default App;
