import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAward,
	faRibbon,
	faShippingFast,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const incentives = [
	{
		icon: faAward,
		title: "Quality Materials",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec consequat lorem.",
	},
	{
		icon: faShippingFast,
		title: "Quality Materials",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec consequat lorem.",
	},
	{
		icon: faRibbon,
		title: "Quality Materials",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec consequat lorem.",
	},
];

const IncentiveItem = ({ item }) => (
	<div className="col-span-12 sm:col-span-6 lg:col-span-4">
		<div className="p-6 md:p-12">
			<div className="text-6xl text-blue-600">
				<FontAwesomeIcon icon={item.icon} />
			</div>
			<h3 className="my-4 text-2xl font-medium">{item.title}</h3>
			<p className="text-sm opacity-70">{item.desc}</p>
		</div>
	</div>
);

IncentiveItem.propTypes = {
	item: PropTypes.object.isRequired,
};

const Incentives = () => {
	return (
		<section className="ezy__epincentives4_EI1ZSyNg py-14 md:py-24 bg-white dark:bg-[#0b1727] text-black dark:text-white relative overflow-hidden z-10">
			<div className="container px-4 mx-auto">
				<div className="flex max-w-3xl mx-auto justify-center text-center">
					<div>
						<h1 className="text-2xl md:text-[45px] leading-none font-bold mb-6">
							We built best Business for you.
						</h1>
						<p className="text-base leading-normal opacity-70 mb-12">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
							nec consequat lorem. Maecenas elementum at diam consequat
							bibendum.
						</p>
					</div>
				</div>
				<div className="bg-gray-100 dark:bg-slate-800 rounded-lg p-6 mt-12">
					<div className="grid grid-cols-12 text-center">
						{incentives.map((item, i) => (
							<div className="col-span-12 sm:col-span-6 lg:col-span-4" key={i}>
								<IncentiveItem item={item} />
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Incentives;

