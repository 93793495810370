import React, {Fragment} from 'react';
import Epoverview from "../components/Epoverview";


const ProductDetails = () => {
    return (
        <Fragment>
            <Epoverview />
        </Fragment>
    );
}

export default ProductDetails;
