
export const getAllMakes = () =>{
    return fetch('https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json')
    .then(val=> val.json())
}

export const getAllManufacturers = (page = 1) =>{
    return fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/getallmanufacturers?format=json&page=${page}`)
    .then(val=> val.json())
}

export const getModelsFromMake = (make = 'honda') =>{
    return fetch( `https://vpic.nhtsa.dot.gov/api/vehicles/getmodelsformake/${make}?format=json`)
    .then(val=> val.json())
}
      
export const getMakesForVehicleType = (type = 'car') =>{
    return fetch( `https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/${type}?format=json`)
    .then(val=> val.json())
}
      


export const getModelsForMakeId = (makeId = '440') =>{
    return fetch( `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/${makeId}?format=json
`)
    .then(val=> val.json())
}