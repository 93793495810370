import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHeart,
	faShoppingCart,
	faStar,
	faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import PropTypes from "prop-types";
import classNames from "classnames";

const products = [
	{
		img: "https://images.unsplash.com/photo-1459603677915-a62079ffd002?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhcnxlbnwwfHwwfHx8MA%3D%3D",
		title:
			"2023 - BUICK - Encore GX (Pass Car_Light Truck)",
		subTitle: "L3 1.2L 1200CC 73CID (Turbo LIH Gas )",
		spec: {
			Oil: "410029031002902100290100290",
			Fuel: "NS",
			Air: "201180",
			Trans: "NS",
			Hydraulics: "",
		},
		discountPrice: "1199.5",
		realPrice: "2399",
		discount: "-50%",
		rating: 3.5,
		count: "8",
		shipping: "50",
		availibility: "Available",
	},
	{
		img: "https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGNhcnxlbnwwfHwwfHx8MA%3D%3D",
		title:
			"2022 - HONDA - CB500X ABS (Motorcycle)	",
		subTitle: "Computer & Accessories",
		spec: {
			Oil: "410029031002902100290100290",
			Fuel: "NS",
			Air: "201180",
			Trans: "NS",
			Hydraulics: "",
		},
		discountPrice: "1199.5",
		realPrice: "2399",
		discount: "-50%",
		rating: 4.5,
		count: "8",
		shipping: "50",
		availibility: "Available",
	},
	{
		img: "https://images.unsplash.com/photo-1486496572940-2bb2341fdbdf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGNhcnxlbnwwfHwwfHx8MA%3D%3D",
		title:
			"2022 - HONDA - CB500X ABS (Motorcycle)	",
		subTitle: "471 CC",
		spec: {
			Oil: "410029031002902100290100290",
			Fuel: "NS",
			Air: "201180",
			Trans: "NS",
			Hydraulics: "",
		},
		discountPrice: "1199.5",
		realPrice: "2399",
		discount: "-50%",
		rating: 4,
		count: "8",
		shipping: "50",
		availibility: "Available",
	},
];

const Rating = ({ rating }) => (
	<span>
		{[...Array(5)].map((_, i) => {
			const index = i + 1;
			let content = "";
			if (index <= Math.floor(rating))
				content = <FontAwesomeIcon icon={faStar} />;
			else if (rating > i && rating < index + 1)
				content = <FontAwesomeIcon icon={faStarHalfAlt} />;
			else if (index > rating) content = <FontAwesomeIcon icon={farStar} />;

			return <Fragment key={i}>{content}</Fragment>;
		})}
	</span>
);

Rating.propTypes = {
	rating: PropTypes.number,
};



const SpecificationItem = ({ item }) => <li>{item}</li>;

SpecificationItem.propTypes = {
	item: PropTypes.object.isRequired,
};

const ProductItem = ({ product }) => {
	return (
		<div className="grid grid-cols-12">
			{/* image */}
			<div className="col-span-12 xl:col-span-4 relative">
				<h6 className="absolute top-3 right-5 bg-green-500 text-white py-1 px-3 rounded-2xl">
					New
				</h6>

				<a href="#!">
					<img
						src={product.img}
						alt="..."
						className="rounded-lg max-w-full h-auto w-full max-h-full"
					/>
				</a>
			</div>
			<div className="col-span-12 lg:col-span-7 xl:col-span-5 pt-6 xl:pt-0 lg:px-6">
				<a href="#!">
					<h5 className="text-[19px] font-medium leading-tight hover:underline">
						{product.title}
					</h5>
				</a>
				<a href="#!">
					<h5 className="text-sm leading-none opacity-60 hover:underline font-medium mt-2">
						{product.subTitle}
					</h5>
				</a>
				<ul className="text-base list-disc opacity-60 pl-6 pt-6">
					{Object.keys(product.spec).map((item, i) => (
						<SpecificationItem item={product.spec[item]} key={i} />
					))}
				</ul>
			</div>
			<div className="col-span-12 lg:col-span-5 xl:col-span-3 pt-6 xl:pt-0">
				<div className="mb-6">
					<h5 className="text-blue-600 font-medium text-2xl">
						${product.discountPrice}
					</h5>
					<div className="text-sm font-medium opacity-50 flex items-center">
						<h5 className="text-sm font-medium line-through mb-0 mr-1">
							${product.realPrice}
						</h5>
						<span>{product.discount}</span>
					</div>
				</div>
				<div className="flex flex-col my-4">
					<li className="mb-1">
						<div className="flex items-center text-[15px] text-yellow-500">
							<Rating rating={product.rating} />
							<span className="opacity-70 text-black dark:text-white ml-1">
								({product.count})
							</span>
						</div>
					</li>
					<li>
						<span className="text-sm opacity-60">
							Shipping Cost: ${product.shipping}
						</span>
					</li>
					<li>
						<span className="text-sm opacity-60">
							Stock:{" "}
							<span className="text-green-500">{product.availibility}</span>
						</span>
					</li>
				</div>

				{/* icons */}
				<div className="flex flex-col mt-6">
					<button className="text-blue-600 hover:bg-blue-600 border border-blue-600 rounded py-1 px-3 hover:text-white mr-2">
						<FontAwesomeIcon icon={faHeart} className="mr-2" />
						<span>Favourite</span>
					</button>
					<button className="bg-blue-600 text-white hover:bg-opacity-90 py-1 px-3 rounded mr-2 mt-2">
						<FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
						<span>Add to Cart</span>
					</button>
				</div>
			</div>
		</div>
	);
};

ProductItem.propTypes = {
	product: PropTypes.object.isRequired,
};

const Eproductlist = () => {
	return (
		<section className="ezy__eproductlist1_tWbHhCLP py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative overflow-hidden z-10">
			<div className="container px-4 mx-auto">
				<div className="col-span-12 xl:col-span-4 relative">
					{products.map((product, i) => (
						<div className={classNames({ "mt-5": i })} key={i}>
							<ProductItem product={product} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Eproductlist;

