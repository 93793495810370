import React, {Fragment} from 'react';
import EPGrid from "../components/EPGrid";


const ProductListing = () => {
    return (
        <Fragment>
            <EPGrid />

        </Fragment>
    );
}

export default ProductListing;
