import React, { Fragment } from "react";

const user = {
	previewInfo: [
		{
			property: "USER NAME",
			value: "INFO@JESSICA.COM",
		},
		{
			property: "FIRST NAME",
			value: "JESSICA",
		},
		{
			property: "LAST NAME",
			value: "BROWN",
		},
		{
			property: "PHONE NUMBER",
			value: "1-800-123-HELLO",
		},
		{
			property: "DATE OF BIRTH",
			value: "15 AUGUST 1985",
		},
		{
			property: "STREET ADDRESS AND NUMBER",
			value: "353 THIRD FLOOR AVENUE",
		},
		{
			property: "TOWN / CITY",
			value: "PARIS, FRANCE",
		},
		{
			property: "ZIP CODE",
			value: "75800-875",
		},
		{
			property: "COUNTRY",
			value: "UNITED STATES OF AMERICA",
		},
	],
	name: "Jessica Brown",
	profileImage: "https://cdn.easyfrontend.com/pictures/profile1-user.png",
	backgroundImage: "https://cdn.easyfrontend.com/pictures/profile1-bg.png",
};

const EProfileInfo = () => {
	return (
		<div className="col-span-12 md:col-span-9 lg:col-span-10">
						<div className="bg-white dark:bg-slate-800 shadow-2xl rounded-xl p-4 md:p-6">
							<div
								className="bg-cover bg-center bg-no-repeat min-h-[350px] rounded-xl mb-[150px] relative"
								style={{
									backgroundImage: `url(${user.backgroundImage})`,
								}}
							>
								<img
									src={user.profileImage}
									alt=""
									className="absolute -bottom-24 left-1/2 -translate-x-1/2 rounded-full w-52 h-52 object-cover"
								/>
							</div>
							<div className="lg:px-12">
								<h1 className="text-3xl md:text-[40px] font-bold leading-tight text-center">
									{user.name}
								</h1>
								<div className="grid grid-cols-2 gap-2 justify-between mt-6 md:mt-12">
									{user.previewInfo.map((item, i) => (
										<Fragment key={i}>
											<div className="col-span-1">
												<p className="uppercase text-blue-600 mb-2">
													{item.property}:
												</p>
											</div>
											<div className="col-span-1 lg:col-auto">
												<p className="text-end mb-2">{item.value}</p>
											</div>
										</Fragment>
									))}
								</div>
							</div>

							<div className="my-12 lg:px-12">
								<h6 className="text-3xl font-bold mb-6">About Me</h6>
								<p className="text-justify opacity-75">
									Vestibulum tristique, justo eu sollicitudin sagittis, metus
									dolor eleifend urna, quis scelerisque purus quam nec ligula.
									Suspendisse iaculis odio odio, ac vehicula nisi faucibus eu.
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Suspendisse posuere semper sem ac aliquet. Duis vel bibendum
									tellus, eu hendrerit sapien. Proin fringilla, enim vel
									lobortis viverra, augue orci fringilla diam, sed cursus elit
									mi vel lacus. Nulla facilisi. Fusce sagittis, magna non
									vehicula gravida ac rhoncus vestibulum.
								</p>
							</div>
						</div>
					</div>
	);
};

export default EProfileInfo;

