
import React from "react";
import ProfileSideBar from "../components/profile/ProfileSideBar";
import { Outlet } from 'react-router-dom';



const Profile = () => {
    return (
        <div className="Profile" style={{ display: 'flex', flexDirection: "row"}}>
            <div style={{ width: '20%'}}><ProfileSideBar /></div>
            <div style={{ width: '80%'}}><Outlet/></div>
            
        </div>
    );
};

export default Profile;

