import React from "react";

const orders = [
	{
		imageSrc: "https://plus.unsplash.com/premium_photo-1673208484517-deeab27359b4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGNhciUyMHBhcnRzfGVufDB8fDB8fHww",
		name: "Filter 1",
		items: 6,
		price: 120,
		date: "Yesterday",
	},
	{
		imageSrc: "https://plus.unsplash.com/premium_photo-1682144947715-47824678d027?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGNhciUyMHBhcnRzfGVufDB8fDB8fHww",
		name: "Filter 2",
		items: 6,
		price: 120,
		date: "Yesterday",
	},
	{
		imageSrc: "https://images.unsplash.com/photo-1717068341263-33331ec8104c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fGNhciUyMHBhcnRzfGVufDB8fDB8fHww",
		name: "Filter 3",
		items: 6,
		price: 120,
		date: "Yesterday",
	},
	{
		imageSrc: "https://images.unsplash.com/photo-1710130168142-d2ec07ed8434?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fGNhciUyMHBhcnRzfGVufDB8fDB8fHww",
		name: "Filter 4",
		items: 6,
		price: 120,
		date: "Yesterday",
	},
	{
		imageSrc: "https://plus.unsplash.com/premium_photo-1693828616989-6a33711d870a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDF8fGNhciUyMHBhcnRzfGVufDB8fDB8fHww",
		name: "Filter 5 (Blue)",
		items: 6,
		price: 120,
		date: "Yesterday",
	},
];



const EProfileOrders = () => {
	return (
		<div className="col-span-12 md:col-span-9 lg:col-span-10">
						<div className="bg-white dark:bg-slate-800 shadow-2xl rounded-xl p-6 pl-12">
							<h3 className="text-[26px] md:text-[32px] font-bold leading-tight">
								Order History
							</h3>
						</div>
						{orders.map((order, index) => (
							<div
								key={index}
								className="bg-white dark:bg-slate-800 shadow-2xl rounded-xl p-6 mt-6"
							>
								<div className="grid grid-cols-12 gap-2 sm:gap-6 items-center">
									<div className="col-span-12 lg:col-span-3">
										<div className="flex items-center justify-center">
											<img
												src={order.imageSrc}
												alt=""
												className="max-w-full h-auto"
											/>
										</div>
									</div>
									<div className="col-span-12 sm:col-span-5">
										<div>
											<h6 className="text-xl sm:text-2xl font-bold md:ml-4">
												{order.name}
											</h6>
										</div>
									</div>
									<div className="col-span-6 sm:col-span-2">
										<div className="text-center">
											<p className="text-xl">
												{order.items} <br /> Items
											</p>
										</div>
									</div>
									<div className="col-span-6 sm:col-span-2">
										<div className="text-center">
											<h6 className="text-3xl font-bold mb-2">
												${order.price}
											</h6>
											<p>{order.date}</p>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
	);
};

export default EProfileOrders;

