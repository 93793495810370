import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const routes = [
	{ name: "Home", href: "/", isActive: true },
	{ name: "About Us", href: "/about-us", isActive: false },
	{ name: "Products", href: "/product-listing", isActive: false },
	{ name: "Product Overview", href: "/product-details", isActive: false },
	{ name: "Cart", href: "/cart", isActive: false },
	{ name: "Profile", href: "/profile/info", isActive: false },
];

const NavMenu = () => {
	return routes.map((route, i) => (
		<li key={i}>
			
			<Link
				to={route.href}
				style={{ display: "inline-block", fontWeight: "600", lineHeight: "1.5", color: "#1e4148", textAlign: "center", cursor: "pointer", padding: "0.5rem 0.75rem", fontSize: "14px", borderRadius: "0.25rem", backgroundColor: "transparent", textDecoration: "none", border: "1px solid #dee4e4" }}
			>
				{route.name}
			</Link>
		</li>
	));
};

// const AuthNavMenu = () => (
// 	<ul className="flex flex-row ml-auto mb-2 lg:mb-0">
// 		<li className="nav-item">
// 			<button className="bg-blue-600 text-white hover:bg-opacity-90 rounded-lg py-1.5 px-4">
// 				Signup or Login
// 			</button>
// 		</li>
// 	</ul>
// );

const SearchForm = () => (
	<li>
		<form className="lg:mx-4 mb-4 lg:mb-0">
			<div className="flex rounded-lg overflow-hidden">
				<input
					className="min-h-[48px] grow px-4 bg-[#a3bef124]"
					type="search"
					placeholder="City, Address, Zip"
					aria-label="Search"
				/>
				<button
					className="bg-blue-600 text-white hover:bg-opacity-90 min-h-[48px] px-4"
					type="submit"
				>
					<FontAwesomeIcon icon={faSearch} />
				</button>
			</div>
		</form>
	</li>
);

const Navigation = () => {
	return (
		<div className="ezy__nav3_xbTkxDuk py-6 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative">
			<nav>
				<div className="container px-4">
					<div className="flex items-center justify-between lg:justify-start">
						<a className="font-black text-3xl" href="#!">
							{" "}
							<img alt='GPC' style={{ width: '50px'}} src="/logo.png"/>
							{" "}
						</a>
						<button
							className="block lg:hidden cursor-pointer h-10 z-20"
							type="button"
							id="hamburger"
						>
							<div className="h-0.5 w-7 bg-black dark:bg-white -translate-y-2"></div>
							<div className="h-0.5 w-7 bg-black dark:bg-white"></div>
							<div className="h-0.5 w-7 bg-black dark:bg-white translate-y-2"></div>
						</button>
						<div className="flex flex-col lg:flex-row justify-between items-center text-3xl gap-6 lg:text-base lg:gap-2 absolute h-screen w-screen top-0 left-full lg:left-0 lg:relative lg:h-auto lg:w-auto bg-white dark:bg-[#0b1727] lg:bg-transparent grow">
							<ul className="flex items-center mb-2 lg:mb-0 mt-6 lg:mt-0">
								<SearchForm />
								<NavMenu />
							</ul>

							{/* <AuthNavMenu /> */}
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navigation;

