import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import AboutUs from "./pages/AboutUs.js";
import ProductListing from "./pages/ProductListing.js";
import Cart from "./pages/Cart.js";
import ProductReviews from "./pages/ProductReviews.js";
import ProductDetails from "./pages/ProductDetails.js";
import Profile from "./pages/Profile.js";

import EProfileInfo from "./components/profile/EProfileInfo";
import EProfileSettings from "./components/profile/EProfileSettings";
import EProfileOrders from "./components/profile/EProfileOrders";


const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
				<Route exact path="/about-us" element={<AboutUs />} />
				<Route exact path="/product-listing" element={<ProductListing />} />
				<Route exact path="/cart" element={<Cart />} />
				<Route exact path="/product-reviews" element={<ProductReviews />} />
				<Route exact path="/product-details" element={<ProductDetails />} />
				<Route exact path="/profile" element={<Profile />} />
				<Route path="/profile" element={<Profile />}>
					<Route exact path="/profile/info" element={<EProfileInfo />} />
					<Route exact path="/profile/settings" element={<EProfileSettings />} />
					<Route exact path="/profile/orders" element={<EProfileOrders />} />
				</Route>
				<Route exact path="/profile/info" element={<EProfileInfo />} />
				<Route exact path="/profile/settings" element={<EProfileSettings />} />
				<Route exact path="/profile/Orders" element={<EProfileOrders />} />
        </Routes>
    );
};

export default AppRoutes;
