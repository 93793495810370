import React, {Fragment} from 'react';
import AboutUsComponent from "../components/AboutUs";
import FreeReturnsSection from "../components/FreeReturnsSection";


const AboutUs = () => {
    return (
        <Fragment>

            <AboutUsComponent />

            <FreeReturnsSection />
        </Fragment>
    );
}

export default AboutUs;
