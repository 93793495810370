import React, {Fragment} from 'react';
import Epcart from "../components/Epcart";


const Cart = () => {
    return (
        <Fragment>

            <Epcart />

        </Fragment>
    );
}

export default Cart;
