import React, {Fragment,  useState} from 'react';
import Incentives from "../components/Incentives";
import Eproductlist from "../components/Eproductlist";
import {Dropdown} from "../components/Dropdown";
import {  getMakesForVehicleType, getModelsForMakeId } from '../api/vehicleData';


const vehicleTypes = [

    {
        label: 'car',
        value: 'car'
    },
    {
        label: 'truck',
        value: 'truck'
    }
]
const Home = () => {

    const [allMakes, setAllMakes] = useState([]);

    
    // const [allManufactures, setAllManufactures] = useState([]);
    
    
    const [allModels, setAllModels] = useState([]);

    const [showList, setShowList] = useState(false);





    const selectVehicleType = (item) => {
        getMakesForVehicleType(item.value).then(val=>{
            setAllMakes(val.Results.map(item=> {
                return {
                    ...item,
                    label: item.MakeName,
                    value: item.MakeId
                }
            }));
        })
        setShowList(false);
    }

    const selectMake = (item) => {
        getModelsForMakeId(item.value).then(val=>{
            setAllModels(val.Results.map(item=> {
                return {
                    ...item,
                    label: item.Model_Name,
                    value: item.Model_ID
                }
            }));
        })
        setShowList(false);
    }

    const selectModel = (item) => {
        // getModelsFromMake(item.value).then(val=>{
        //     setAllModels(val.Results.map(item=> {
        //         return {
        //             ...item,
        //             label: item.Model_Name,
        //             value: item.Model_ID
        //         }
        //     }));
        // })
        setShowList(true);
    }
    return (
        <Fragment>
           
            <Dropdown label={'Vehicle Type'} data={vehicleTypes} selectValue= {selectVehicleType}/>
            <Dropdown label={'Make'} data={allMakes} selectValue= {selectMake}/>
            <Dropdown label={'Model'} data={allModels} selectValue= {selectModel}/>
			
            {showList && <Eproductlist />}


            <Incentives />

        </Fragment>
    );
}

export default Home;
